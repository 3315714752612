import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from '../Utilities/HttpUtils';

export function usePrivileges(businessUnitCode) {

    const [canProcess, setCanProcess] = useState();
    const [canReview, setCanReview] = useState();
    const [canGrade, setCanGrade] = useState();
    const [supervisor, setSupervisor] = useState();
    const [admin, setAdmin] = useState();

    useEffect(() => { getPrivileges(businessUnitCode) }, []);

    async function getPrivileges(businessUnitCode) {
        let canProcess = await getAuthenticatedAccountPrivilege('CanProcessPrivilegeName', businessUnitCode);
        setCanProcess(canProcess);

        let canReview = await getAuthenticatedAccountPrivilege('CanReviewPrivilegeName', businessUnitCode);
        setCanReview(canReview);

        let canGrade = await getAuthenticatedAccountPrivilege('CanGradePrivilegeName', businessUnitCode);
        setCanGrade(canGrade);

        let supervisor = await getAuthenticatedAccountPrivilege('SupervisorPrivilegeName', businessUnitCode);
        setSupervisor(supervisor);

        let admin = await getAuthenticatedAccountPrivilege('AdminPrivilegeName', businessUnitCode);
        setAdmin(admin)
    }

    return { canProcess, canReview, canGrade, supervisor, admin };
}

async function getAuthenticatedAccountPrivilege(privilegeName, businessUnitCode) {
    let accessGranted = false;

    let url = `api/v1/user/checkPrivilege?privilegeName=${privilegeName}`;

    if (businessUnitCode) {
        url += `&businessUnitCode=${businessUnitCode}`;
    }

    await fetchDataAuthenticated(url)
        .then(response => {
            if (response.ok) {
                accessGranted = true;
            } else if (response.status === 403) {
                throw new Error(`You do not have privilege ${privilegeName} for business unit ${businessUnitCode}`);
            } else {
                throw new Error(`Failed to check privilege ${privilegeName} for business unit ${businessUnitCode}`);
            }
        })
        .catch(error => console.error(error.message));

    return accessGranted;
}

export default usePrivileges;