import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import DataTable from '../DataTable/datatable.component';
import { Link } from 'react-router-dom';
import DateColumn from '../DataTable/datatable.datecolumn';
import { getTableFilters, getSortOrder } from '../Utilities/SessionStorage';
import SelectFilter from '../DataTable/datatable.selectfilter';

function LotListTable({ lots, tableFilters, tableName }) {

    const { t } = useTranslation();

    const columns = [
        {
            Header: t('LotList.ColHead.BusinessUnit'),
            accessor: 'businessUnit',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: t('LotList.ColHead.LotNumber'),
            accessor: 'lotNumber',
            Cell: (props) => {
                return <><Link to={{ pathname: `/lotdetails/${props.value}` }}>{props.value}</Link> {props.row.original.isBlocked && <FontAwesomeIcon icon={faLock} title={t('LotStatus.Blocked')} />} </>
            },
            filter: 'fuzzyText'
        },
        {
            Header: t('LotList.ColHead.Status'),
            accessor: 'lotStatus',
            Filter: SelectFilter,
            filter: 'equals',
            Cell: (props) => {
                return t('LotStatus.' + props.value);
            }
        },
        {
            Header: t('LotList.ColHead.Grade'),
            accessor: 'grade',
            filter: 'fuzzyText'
        },
        {
            Header: t('LotList.ColHead.Weight'),
            accessor: 'lotWeight',
            filter: 'NoFilter'
        },
        DateColumn({ header: t('LotList.ColHead.BuildStart'), accessor: 'buildStart' }),
        DateColumn({ header: t('LotList.ColHead.BuildEnd'), accessor: 'buildEnd' }),
        {
            Header: t('LotList.ColHead.LastModifiedBy'),
            accessor: 'lastModifiedBy',
            filter: 'fuzzyText'
        },
        {
            Header: t('LotList.ColHead.LastComment'),
            accessor: 'lastGradingComment',
            filter: 'fuzzyText'
        }
    ];

    const initialState = {
        pageSize: 20
    }

    const tableId = tableName ?? "lots-table";
    const sortBy = getSortOrder(tableId);
    const filters = getTableFilters(tableId);

    if (sortBy) {
        initialState.sortBy = sortBy;
    }

    if (filters) {
        initialState.filters = filters;
    }

    let noLotsMessage = "";
    if (tableFilters.status) {
        noLotsMessage = <div>No {!tableFilters.includeGraded ? "ungraded" : ""} lots with status <strong>{t('LotStatus.' + tableFilters.status)}</strong> to show</div>
    } else {
        noLotsMessage = <div>No {!tableFilters.includeGraded ? "ungraded" : ""} lots to show</div>;
    }

    return lots.length > 0
        ? <DataTable id={tableId} tableColumns={columns} tableData={lots} initialState={initialState} />
        :
        <div className="d-flex flex-column mt-5">
            <h4 className="text-center">{noLotsMessage}</h4>
            <p className="text-center text-muted"><sm>Check filters if lots are expected</sm></p>
        </div>
}

export default LotListTable;