import React from 'react';
import { Toast ,ToastContainer } from 'react-bootstrap';
import useAlert from './useAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCircleExclamation, faCircleCheck, faCircleInfo, faComment } from '@fortawesome/free-solid-svg-icons';
import './NotificationAlert.css';

function NotificationAlert() {
    const { alerts, dismissAlert } = useAlert();

    const useWhiteFont = ["danger", "success", "primary", "secondary", "dark"];
    const noAutoDismiss = ["danger"];
    function GetVariantIcon(variant) {
        switch (variant) {
            case "danger":
                return faCircleExclamation;
            case "warning":
                return faTriangleExclamation;
            case "success":
                return faCircleCheck;
            case "info":
                return faCircleInfo;
            default:
                return faComment;
        }
    }
    return (
        <ToastContainer className="notification-alert">
            {alerts.map(({ variant, header, text, alertNumber, showAlert }) => (
                <Toast
                    key={"toast_" + alertNumber}
                    className="d-inline-block m-1"
                    bg={variant.toLowerCase()}
                    onClose={() => dismissAlert(alertNumber)}
                    show={showAlert}
                    animation
                    autohide = {!noAutoDismiss.includes(variant)}
                    delay={5000}
                >
                    <Toast.Header className= "toast-header-background">
                        <FontAwesomeIcon
                            icon={GetVariantIcon(variant)}
                            className="me-3"
                            inverse={useWhiteFont.includes(variant)}
                        />
                        <strong className={`me-auto ${useWhiteFont.includes(variant) ? "text-white" : ""}`}>
                            {header}
                        </strong>
                        </Toast.Header>
                    <Toast.Body className="toast-body-background">
                            {text}
                    </Toast.Body>
                </Toast>
            ))}
        </ToastContainer>
    );
}

export default NotificationAlert;